import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const TakenSquare = props => <span className="square"> {props.value} </span>
const EmptySquare = props => <span className="square" onClick={props.onClick}></span>

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const squares = Array(9).fill(null);
    const playerSequence = playerSequenceGenerator('X', 'O');
    const currentPlayer = playerSequence.next().value;
    const [gameIsOver, gameStateMessage] = getGameState(squares, currentPlayer);
    return {
      squares,
      playerSequence,
      currentPlayer,
      gameIsOver,
      gameStateMessage
    };
  }

  render() {
    return (
      <div className="board">
        <div className="status">{this.state.gameStateMessage}</div>
        <div className="square-grid">
          {this.renderSquare(0)}
          {this.renderSquare(1)}
          {this.renderSquare(2)}
          {this.renderSquare(3)}
          {this.renderSquare(4)}
          {this.renderSquare(5)}
          {this.renderSquare(6)}
          {this.renderSquare(7)}
          {this.renderSquare(8)}
        </div>
        {this.renderResetButton()}
      </div>
    );
  }

  renderSquare(i) {
    return (
      this.state.squares[i] === null && !this.state.gameIsOver
        ? (<EmptySquare onClick={this.onEmptySquareClick.bind(this, i)} />)
        : (<TakenSquare value={this.state.squares[i]} />)
    );
  }

  onEmptySquareClick(i) {
    let squares = this.state.squares.slice();
    squares[i] = this.state.currentPlayer;
    const nextPlayer = this.state.playerSequence.next().value;
    const [gameIsOver, gameStateMessage] = getGameState(squares, nextPlayer);
    this.setState({
      squares,
      currentPlayer: nextPlayer,
      gameIsOver,
      gameStateMessage
    });
  }

  renderResetButton() {
    if (this.state.gameIsOver) {
      return (<button className="reset-button" onClick={() => this.setState(this.getInitialState())}>reset</button>);
    }
    return null;
  }
}

function* playerSequenceGenerator(...players) {
    let playersRing = players.slice();
    while (true) {
        let currentPlayer = playersRing.shift();
        playersRing.push(currentPlayer);
        yield currentPlayer;
    }
}

function getGameState(squares, nextPlayer) {
  const winningLines = [
    // rows
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    // columns
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    // diagonals
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (const line of winningLines) {
    const [a, b, c] = line;
    if (squares[a] && squares[a] === squares[b] && squares[b] === squares[c]) {
      return [true, `${squares[a]} wins!`];
    }
  }
  if (squares.every(value => value !== null)) {
    return [true, "Cat's game."];
  }
  return [false, `It's ${nextPlayer}'s turn.`]
}

ReactDOM.render(
  <Board />,
  document.getElementById('root')
);
